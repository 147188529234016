import axios from "axios";

export default class AIAssistantService {
  model;
  url;
  messages;
  stream;

  constructor() {
    this.model = process.env.VUE_APP_AI_DEFAULT_MODEL;
    this.url = `${process.env.VUE_APP_OLLAMA_URL}/api/chat`;
    this.messages = [];
    this.stream = false;
  }
  addNewMessage(message) {
    this.messages.push(message);
    return this;
  }
  emptyMessage() {
    this.messages = [];
    return this;
  }

  /**
   * Setter function ends here
   */

  /** ------------------------------------- **/
  /**
   * Getter function starts here
   */

  hasUserMessage() {
    return this.messages.filter(message => message.role === 'user').length > 0;
  }

  /** ------------------------------------- **/

  /**
   * private methods starts here
   */
  async #sendAxiosRequest(payload) {
    const response = await axios.post(this.url, payload);

    if (response) {
      const content = response.data.message.content.replace('\n', '').replace('`', '');
      let messageData = {
        role : 'assistant',
        content : content
      }
      this.messages.push(messageData);
      return {
        content : content,
        total_duration : response.data.total_duration
      }
    }
    return {
      content : '',
      total_duration : response.data.total_duration
    }
  }

  async #sendFetchRequest() {
    try {
      const response = await fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          model: this.model,
          messages : this.messages
        }),
      });

      return response.body.getReader();

    } catch (error) {
      console.error('Error fetching stream:', error);
    }
  }
  /**
   * private methods ends here
   */

  /** ------------------------------------- **/


  async requestMessage() {
    let payload = {
      model: this.model,
      messages: this.messages
    };

    if (this.stream === false) {
      payload.stream = false;
    }

    if (this.stream === false) {
      return await this.#sendAxiosRequest(payload);
    }
    return await this.#sendFetchRequest();
  }
}
