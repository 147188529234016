import axios from "axios";
import AIAssistantService from "@/services/Ollama/AIAssistantService";

export default class DataFieldAssistant extends AIAssistantService{
  #instructionPrompt;
  #data_module;
  #field_meaning_verification;
  #field_description;
  #predefined_data_key;
  #predefined_data_values;
  #two_sample_questions;
  constructor() {
    super();
    this.initialize()

  }
  initialize() {
    this.#instructionPrompt = '';
    this.#data_module = '';
    this.#field_meaning_verification = '';
    this.#field_description = '';
    this.#predefined_data_key = '';
    this.#predefined_data_values = [];
    this.#two_sample_questions = [];
  }
  setDataModule(data_module) {
    this.#data_module = data_module;
    return this;
  }
  setMeaningVerification(field_meaning_verification) {
    this.#field_meaning_verification = field_meaning_verification;
    return this;
  }

  setFieldDescription(field_description) {
    this.#field_description = field_description;
    return this;
  }

  setPredefinedDataKey(predefined_data_key) {
    this.#predefined_data_key = predefined_data_key;
    return this;
  }

  setPredefinedDataValues(predefined_data_values) {
    this.#predefined_data_values = predefined_data_values;
    return this;
  }
  setInstructionPrompt(instructionPrompt) {
    this.#instructionPrompt = instructionPrompt;
    return this;
  }

  setTwoSampleQuestions(twoQuestions) {
    this.#two_sample_questions = twoQuestions;
    return this;
  }
  #constructMessageForTwoQuestions() {
    let message = ``;

    this.#two_sample_questions.forEach((item, index) => {
      message +=
        `${index + 1}. "${item.question}"
  Output:
  {
      "${this.#predefined_data_key}" : "${item.value}"
  }

`
    });

    return message;
  }
  replaceValuesToPrompt() {

    const messageForTwoQuestions = this.#constructMessageForTwoQuestions();

    this.#instructionPrompt = this.#instructionPrompt
      .replace(/\[report_module\]/g, this.#data_module)
      .replace(/\[field_meaning_verification\]/g, this.#field_meaning_verification)
      .replace(/\[field_description\]/g, this.#field_description)
      .replace(/\[field_name\]/g, this.#predefined_data_key)
      .replace(/\[two_sample_valid_questions\]/g, messageForTwoQuestions)
      .replace(/\[predefined_values\]/g, this.#predefined_data_values.join(', '))
      .replace(/\[valid_output_value\]/g, this.#predefined_data_values.slice(0, 2)[0])
      .replace(/\[valid_values_sample\]/g, this.#predefined_data_values.slice(0, 2).join(', '))
    ;

    return this;
  }

  getInstructionPrompt() {
    return this.#instructionPrompt;
  }




}
