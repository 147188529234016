<script>
import AppLoading from "@/components/elements/AppLoading.vue";
import {mapActions, mapGetters} from "vuex";
import AIAssistantService from "@/services/Ollama/AIAssistantService";

import {
  DATA_FIELD_ASSISTANT_PROMPT, INTENT_IDENTIFIER_ASSISTANT_PROMPT,
  NARRATOR_EXPERT_PROMPT, QUESTION_COMPARISON_ASSISTANT_PROMPT,
  SQL_EXPERT_PROMPT
} from "@/services/Ollama/constants/AssistantPromptConstants";
import {AiService} from "@/services/AiService";
import DataFieldAssistant from "@/services/Ollama/DataFieldAssistant";
import ca from "vue2-datepicker/locale/es/ca";

export default {
  name: "AIChatBox",
  components: {AppLoading},
  data() {
    return {
      elite_icon: require('@/assets/elite-icon.png'),
      ai_brain: require('@/assets/ai-brain.svg'),
      questions : [],
      answers   : [],
      current_question : '',
      questionTextArea : null,
      AIAssistants : {},
      totalDuration : 0,
      processedAssistant : 0
    }
  },
  methods : {
    ...mapActions([
      'getHelperFields'
    ]),
    hideModal() {
      $('#ai-chat-generator').modal('hide')
    },
    setNarratorTemplateAnswer(question_intent, data, for_report_number = false) {
      let downloadButton = false;
      let message = ``;
      const fiveReports = data.slice(0, 5);
      let totalCount = data.length;

      if (totalCount === 0) {
        message += `After looking deeply from the database, unfortunately we're sorry because there is no result from your query. Thanks for waiting!`;
      } else {
        if (question_intent.intent === 'list') {
          message += `Here are the list of reports with the total of ${totalCount} based on your query: `;
          fiveReports.forEach(report => {
            message += `
          Report Number : ${report?.unique_report_number.toUpperCase()}
          Report Title : ${report?.report_title}
          Report Status : ${report?.report_status}
          Vessel Name : ${report?.vessel_name}
          Event Date : ${report?.event_date}
          `
          });

          if (totalCount > 5) {
            message +=
              `
          There are only 5 items to display but if you wish to see full list of reports and their details, kindly click the "DOWNLOAD REPORTS" button below.
        `;
            downloadButton = true;
          }
        } else {
          if ('COUNT(*)' in data[0] || 'count(*)' in data[0]) {
            totalCount = data[0];
          }

          if (for_report_number === true) {
            message += `Kindly answer that question and summarize it based on this context : ${JSON.stringify(data)}`;
          } else {
            message += `If the question asking of how many reports are then tell the total is ${totalCount}, just only that which means no other explanation. Else kindly answer that question and summarize it based on this context : ${JSON.stringify(data)}`;
          }
        }
      }

      return {
        download_button : downloadButton,
        message : message
      }
    },
    async getQueryAnswer(question, sql_query, question_intent) {
      if (!sql_query) {
        return {
          download_button : false,
          message : 'After looking deeply from the database, unfortunately we\'re sorry because there is no result from your query. Thanks for waiting!'
        }
      }


      const queryResult = await AiService.getGeneratedAiDataByModule({
        module : this.$route.name === 'ReportIndex' ? 'accident_incident' : 'port_state_control',
        query : sql_query
      });

      this.totalDuration += queryResult.total_duration;

      if (queryResult.result === false) {
        if (queryResult.error !== 'query_not_be_empty') {
          const query_result = await this.getSqlScript(question);
          return await this.getQueryAnswer(question, query_result.query, question_intent);
        } else {
          return {
            download_button : false,
            message : 'After looking deeply from the database, unfortunately we\'re sorry because there is no result from your query. Thanks for waiting!'
          }
        }
      } else {
        return this.setNarratorTemplateAnswer(question_intent, queryResult.data);
      }
    },

    async processNarratorResponse(question, answer, download_query) {
      let content = '';

      if (this.AIAssistants.narrator.hasUserMessage() === false) {
        content = `Hello! I am ${this.user.name.toLowerCase()}. `
      } else {
        if (download_query === null) {
          content += `This is the answer from my question: '${question.trim()}': '${answer.message}'.`
        } else {
          content += `This is the answer from my question: '${question.trim()}': '${answer.message}'. Just narrate it by describing it to me.`
        }
      }

      this.AIAssistants.narrator.addNewMessage({
        role : 'user',
        content : content
      });
      const answerLastIndex = this.answers.length - 1;
      const response = await this.AIAssistants.narrator.requestMessage();
      this.answers[this.answers.length - 1].fetching_record = null;


      let counter = 0;
      const charLength = response.content.length;
      const displayCharInterval = setInterval(() => {
        if (counter >= charLength) {
          this.AIAssistants.narrator.addNewMessage({
            role : 'assistant',
            content : this.answers[answerLastIndex].value
          });

          const totalDuration = response.total_duration;
          if (question && totalDuration) {
            this.totalDuration += totalDuration;
          }

          if (answer.download_button === true) {
            this.answers[answerLastIndex].download_sql_query = download_query;
          }

          const total_duration = this.totalDuration;
          this.totalDuration = 0;

          if (question && download_query) {
            AiService.saveAiKidsLogs({
              question : question,
              answer : this.answers[answerLastIndex].value,
              sql_query : download_query,
              module : this.logs_module,
              total_duration : total_duration
            });
          }
          clearInterval(displayCharInterval);
        } else {
          this.answers[answerLastIndex].value += response.content[counter];
          this.answers[answerLastIndex].requesting = false;
          counter++;
        }
      }, 20);
    },
    extractSQLQuery(text) {
      const regex = /SELECT\b[\s\S]*?\bFROM\b[\s\S]*?(?=;|$)/i;

      const match = text.match(regex);
      return match ? match[0].trim() : null;
    },
    async getSqlScript(question, temporaryWhereConditions) {
      let content = ``;
      // if (this.AIAssistants.sql_expert.hasUserMessage() === false) {
      //   content = `Hello! my name is: ${this.user.name.toLowerCase()}. `;
      // }

      if (temporaryWhereConditions !== null) {
        content +=
          `
          Temporary WHERE CONDITIONS: ${temporaryWhereConditions}
          This is the Question: ${question.trim()}`;
      } else {
        content += `
        This is the Question: "${question.trim()}"`
      }
      this.AIAssistants.sql_expert = new AIAssistantService()
      // this.AIAssistants.sql_expert.addNewMessage({
      //   role : 'system',
      //   content : SQL_EXPERT_PROMPT
      // });
      this.AIAssistants.sql_expert.addNewMessage({
        role : 'user',
        content : SQL_EXPERT_PROMPT + content
      });

      const requestResponse = await this.AIAssistants.sql_expert.requestMessage();
      this.totalDuration += requestResponse.total_duration;
      delete this.AIAssistants.sql_expert;

      try {
        const parsedJsonData = JSON.parse(requestResponse.content);

        return {
          query : `select * from accident_incidents ${parsedJsonData.where_condition}`
        }
      } catch (Exception) {
        return {
          query : null
        }
      }
    },
    async getQuestionExtractedFields(question) {
      this.answers[this.answers.length - 1].fetching_record = `Please wait patiently while we search our database for the answer to your query (`;
      let extractedFields = {};
      const reportModule = this.$route.name === 'ReportIndex'? 'Accident Incident' : 'Port State Control';

      for (let field in this.ai_accident_incidents.helper_fields) {
        const data = this.ai_accident_incidents.helper_fields[field].data;
        const two_questions_with_values = this.ai_accident_incidents.helper_fields[field].two_questions_with_values;
        const field_meaning_verification = this.ai_accident_incidents.helper_fields[field].field_meaning_verification;
        const field_description = this.ai_accident_incidents.helper_fields[field].field_description;
        const invalid_field_question = this.ai_accident_incidents.helper_fields[field].invalid_field_question;

        this.AIAssistants[field] = new DataFieldAssistant();

        const fullInstructionPrompt = this.AIAssistants[field]
          .setDataModule(reportModule)
          .setMeaningVerification(field_meaning_verification)
          .setFieldDescription(field_description)
          // .setInvalidFieldQuestion(invalid_field_question)
          .setPredefinedDataKey(field)
          .setPredefinedDataValues(data)
          .setTwoSampleQuestions(two_questions_with_values)
          .setInstructionPrompt(DATA_FIELD_ASSISTANT_PROMPT)
          .replaceValuesToPrompt().getInstructionPrompt();

        // console.log(fullInstructionPrompt);





        this.AIAssistants[field].addNewMessage({
          role : 'user',
          content :  fullInstructionPrompt + question.trim()
        });

        const response = await this.AIAssistants[field].requestMessage();
        this.processedAssistant++;


        this.totalDuration += response.total_duration;
        delete this.AIAssistants[field];
        try {
          const nullValues = [null, 'null', undefined, 'undefined', '', 'n/a']
          const JsonResponse =  JSON.parse(response.content);

          // console.log(field);
          // console.log(data);
          // console.log('selected value: ', JsonResponse[field]);
          // console.log('______________________');

          if (nullValues.includes(JsonResponse[field]) === false) {
            extractedFields[field] = JsonResponse[field];
          }
        } catch (Exception) {

        }
      }

      return extractedFields;

    },
    setTemporaryWhereCondition(extracted_fields) {
      let conditions = [];
      for (let column in extracted_fields) {
        conditions.push(`${column} = '${extracted_fields[column]}'`);
      }

      if (conditions.length === 0) {
        return null;
      }
      let whereCondition = conditions.join(' or ');
      return `where deleted_at is null and (${whereCondition});`;
    },
    async getQuestionIntent(question) {
      const answerLastIndex = this.answers.length - 1;

      this.AIAssistants.intent_identifier = new AIAssistantService();
      this.AIAssistants.intent_identifier.addNewMessage({
          role : 'user',
          content : INTENT_IDENTIFIER_ASSISTANT_PROMPT + question.trim()
        });

      const response = await this.AIAssistants.intent_identifier.requestMessage();
      this.totalDuration += response.total_duration;

      this.processedAssistant = 0;
      this.answers[answerLastIndex].fetching_record = 'Still working on it... almost there!';
      delete this.AIAssistants.intent_identifier;

      try {
        return JSON.parse(response.content);
      } catch (Exception) {
        return {
          intent : "answer"
        }
      }
    },
    getReportNumbers(inputString) {
      const regex = /\bai\d{4}-\d{5}\b/g;
      let matched = inputString.toLowerCase().match(regex);

      if (matched) {
        return matched.map(item => item.toLowerCase())
      }
      return null;
    },
    async requestQuestion() {
      if (this.current_question.trim() === '') {
          return;
      }

      let question = this.current_question;
      this.current_question = '';

      this.questionTextArea.blur();

      const modalConversation = $('#modal-conversation');

      setTimeout(() => {
        this.questionTextArea.focus();
        this.setTextAreaHeightAndScroll();
        modalConversation.scrollTop(modalConversation[0].scrollHeight);
      }, 50);

      this.questions.push({
        value : question,
        visible : true
      });

      this.answers.push({
        value : '',
        requesting : false,
        download_sql_query : null,
        download_processing : false,
        fetching_record : null,
      });

      let sql_query = null;
      let answer_for_query = '';



      const report_numbers = this.getReportNumbers(question);
      if (report_numbers !== null) {
        this.answers[this.answers.length - 1].requesting = true;
        // todo get report with in the report number
        const reports = await AiService.getReportsByUniqueNumber({
          module : this.$route.name === 'ReportIndex' ? 'accident_incident' : 'port_state_control',
          report_numbers : report_numbers
        });

        const narratorResponseTemplate = this.setNarratorTemplateAnswer({
          intent : 'answer'
        }, reports, true)

        return await this.processNarratorResponse(question, narratorResponseTemplate, 'no sql generated since report question is directed to report number');
      }

      // console.log('original question = ', question);
      // console.log('_______________________________');
      // question = await this.reconstructCurrentQuestion();
      //
      // console.log('reconstructed question = ', question);
      // console.log('_______________________________');

      const questionExtractedFields = await this.getQuestionExtractedFields(question);

      console.log('extracted fields from question = ', questionExtractedFields);
      console.log('_______________________________');

      const question_intent = await this.getQuestionIntent(question);

      console.log('intent of the question = ', question_intent.intent);
      console.log('_______________________________');
      //

      let temporaryWhereConditions = null;

      if (Object.keys(questionExtractedFields).length > 0) {
        temporaryWhereConditions = this.setTemporaryWhereCondition(questionExtractedFields);
        // console.log('temporary sql where condition that passed to sql agent for correction = ', temporaryWhereConditions);
        // console.log('_______________________________');
      }
      const query_data = await this.getSqlScript(question, temporaryWhereConditions);

      sql_query = query_data.query;

      answer_for_query = await this.getQueryAnswer(question, sql_query, question_intent);
      // console.log('finalized sql query by sql agent based from question = ', sql_query);
      // console.log('_______________________________');
      await this.processNarratorResponse(question, answer_for_query, sql_query);

    },
    async reconstructCurrentQuestion() {
      const twoLastMessages = this.questions.filter(item => item.visible === true).slice(-2);
      if (twoLastMessages.length === 2) {
        this.AIAssistants.question_comparator = new AIAssistantService();
        this.AIAssistants.question_comparator.addNewMessage({
          role : 'system',
          content : QUESTION_COMPARISON_ASSISTANT_PROMPT
        }).addNewMessage({
          role : 'user',
          content : JSON.stringify({
            first_question : twoLastMessages[0].value,
            second_question : twoLastMessages[1].value
          })
        });
        const response = await this.AIAssistants.question_comparator.requestMessage();
        this.totalDuration += response.total_duration;
        try {
          const parsedContent = JSON.parse(response.content);
          if (parsedContent.connected === true) {
            return parsedContent.new_question;
          }
          return twoLastMessages[1].value;
        } catch (Exception) {
          return twoLastMessages[1].value;
        }
      } else {
        return twoLastMessages[0].value;
      }
    },
    setTextAreaHeightAndScroll() {
      this.questionTextArea.css({
        height : 'auto'
      });
      this.questionTextArea.css({
        height : this.questionTextArea[0].scrollHeight + 'px'
      });

      if (this.questionTextArea[0].scrollHeight >= 100) {
        this.questionTextArea.css({
          overflowY : 'visible'
        });
      } else {
        this.questionTextArea.css({
          overflowY : 'hidden'
        });
      }
    },
    async setQuestionInputEventHandler() {
      let _this = this;
      let submitted = false;
      this.questionTextArea = $(this.$refs['user-question-area']);
      this.questionTextArea = $(this.$refs['user-question-area']);

      this.questionTextArea.on('keydown', function(event) {
        if (event.keyCode === 13) {
          if (event.shiftKey === false) {
            if (_this.current_question === '') {
              event.preventDefault();
            } else {
              if (submitted === false) {
                _this.requestQuestion(false);
                submitted = true;
              } else {
                event.preventDefault();
              }
            }
          } else {
            submitted = false;
          }
        } else {
          submitted = false;
        }
        _this.setTextAreaHeightAndScroll();
      });

      this.questionTextArea.on('keyup', function (event) {
        if (event.keyCode === 13) {
          if (event.shiftKey === false) {
            if (_this.current_question === '') {
              event.preventDefault();
            }
          }
        }
        _this.setTextAreaHeightAndScroll();
      });
    },
    async downloadResultToExcel(answer_index) {
      const sql_query = this.answers[answer_index].download_sql_query;
      this.answers[answer_index].download_processing = true;

      const response = await AiService.downloadDataResult(sql_query);
      this.answers[answer_index].download_processing = false;

      const fileType = 'xlsx';
      let mime_type = 'application/vnd.ms-excel';
      let url = window.URL.createObjectURL(new Blob([response],{type:mime_type}))
      let filename = `download_result.${fileType}`;
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
  },


  async created() {
    setTimeout(() => {
      this.setQuestionInputEventHandler();
    }, 1000);

    this.AIAssistants.narrator = new AIAssistantService();
    // this.AIAssistants.narrator.stream = true;
    this.AIAssistants.narrator.addNewMessage({
      role : 'system',
      content : NARRATOR_EXPERT_PROMPT
    });

    this.questions.push({
      value : '',
      visible : false
    });
    this.answers.push({
      value : '',
      requesting : true
    });

    await this.processNarratorResponse('', '');


    // this.AIAssistants.sql_expert.addNewMessage({
    //   role : 'system',
    //   content : SQL_EXPERT_PROMPT
    // });

    await this.getHelperFields(this.chat_module);

    this.$store.commit('SET_MODULE_VISIBLE', this.chat_module);

  },

  computed : {
    ...mapGetters([
      'reports',
      'user',
      'ai_accident_incidents',
    ]),
    logs_module() {
      if (this.$route.name === 'ReportIndex') {
        return 'accident_incidents_module';
      }
      if (this.$route.name === 'PscIndex') {
        return 'psc_module';
      }
    },

    chat_module() {
      if (this.$route.name === 'ReportIndex') {
        return 'ai_accident_incidents';
      }
      if (this.$route.name === 'PscIndex') {
        return 'ai_psc';
      }
    },

    moduleHeader() {
      if (this.$route.name === 'ReportIndex') {
        return 'Accident Incidents';
      }
      if (this.$route.name === 'PscIndex') {
        return 'Port State Control';
      }
    },
  }
}
</script>

<template>
  <div class="chatBox modal fade text-left" data-backdrop="static" data-keyboard="false" id="ai-chat-generator">
    <form @submit.prevent="">
      <div class="modal-dialog modal-xl modal-dialog-centered" >
        <div class="modal-content">
          <div class="modal-header py-2">
            <div class="w-100 d-flex align-items-center">
              <img :src="ai_brain" alt="" style="height: 45px; width: 45px" class="mr-1">
              <h5 class="font-weight-bold">{{ moduleHeader }}</h5>
            </div>
            <button type="button" class="close" @click="hideModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" id = "modal-conversation">
            <div class="conversation">
              <template v-for="(question, index) in questions">
                <div class="card shadow-sm p-3 question" v-if="question.visible === true">
                  {{ question.value }}
                </div>
                <template v-if="answers[index].value !== undefined">
                  <div class="py-3 w-100 flex-row answer">
                    <div style="width: 5%">
                      <img :src="elite_icon" alt="" style="height: 40px; width: 40px">
                    </div>

                    <div class="card shadow-sm p-3 answer w-100" v-if="answers[index].requesting === false">
                      {{ answers[index].value }}

                      <div class="w-full" v-if="answers[index].fetching_record" style="opacity: 0.7">
                        <i class="loading-text" v-if="answers[index].fetching_record === 'Still working on it... almost there!'">
                          {{ answers[index].fetching_record }}
                        </i>
                        <i v-else class="loading-text">{{ answers[index].fetching_record }}{{ parseInt((processedAssistant / (Object.keys(ai_accident_incidents.helper_fields).length)) * 100) }}%)</i>
                      </div>

                      <div class="w-full mt-2" v-if="answers[index].download_sql_query">
                        <button class="float-right e-btn e-btn-blue-gray force-white-all"
                                :disabled="answers[index].download_processing === true"
                                :class="answers[index].download_processing === true ? 'e-btn-blue-gray-disabled' : ''"
                                @click="downloadResultToExcel(index)"
                                type="button">
                          Download Reports
                        </button>
                      </div>
                    </div>
                    <div class="card shadow-sm p-3 answer w-100" v-else>
                      <div class="flex-row align-middle">
                        <AppLoading :spinner-only="true" :height="'20px'" :width="'20px'"/>
                      </div>
                    </div>
                  </div>

                </template>
              </template>
            </div>
          </div>
          <div class="modal-footer">
            <div class="w-100 d-flex align-items-center p-3 input-and-button-area position-relative">
              <textarea type="text" v-model="current_question" ref="user-question-area" class="question-area form-control-sm" name="" id="user-question-area"></textarea>
              <div class="button-area text-center">
                <button @click="requestQuestion" id="send_question_btn" :disabled="current_question.trim() === ''" :class="[current_question.trim() === '' ? '' : 'active-question-btn']">
                  <font-awesome-icon icon="arrow-up" class="arrow-up e-text-white"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

  </div>
</template>

<style scoped>
.chatBox {
  color: white;
  padding: 10px 10px;
  cursor: pointer;
  width: 99%;
  z-index: 10000;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content {
  height: 90vh;

}


.modal-body {
  overflow-y: auto;
}


.question {
  width: fit-content;
  float: right;
  white-space: pre-wrap;
  background-color: #f4f4f4;
}


.answer {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  white-space: pre-wrap;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .25rem;
  font-family: monospace;
}

.question-area {
  resize: none;
  min-height: 50px;
  max-height: 25dvh;
  overflow-y: hidden;
}

.arrow-up {
  font-size: 15px;
  color: white;
}

#user-question-area {
  width: 95%;
  border: none !important;
  background-color: #f4f4f4;
  outline: none !important;
}

#user-question-area:focus {
  outline: none !important;
  border: none !important;
}

.button-area {
  width: 5%;
  position: absolute;
  right: 22px;
  bottom: 29px;
}

.active-question-btn {
  background-color: #478bca !important;
}
#send_question_btn {
  border-radius: 9999px;
  width: 35px;
  height: 35px;
  border: none;
  margin: auto;
  background-color: #d7d7d7;
}
.input-and-button-area {
  background-color:#f4f4f4;
  opacity: 1;
  border-radius: 26px;
}

.related-question-container {
  width: 95%;
  position: absolute;
  bottom: 90px;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.suggested-question:hover {
  background-color: #e9ecef !important;
  color: #435464 !important;
}

@keyframes loadingLineEffect {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.loading-text {
  font-size: 13px;
  text-align: center;
  display: inline-block; /* Ensures animation only applies to the text */

  /* Transparent text with background gradient */
  color: transparent;
  background-image: linear-gradient(
    90deg,
    black,
    #2c2c2c, /* Dark grey */
    #555555, /* Medium grey */
    #2c2c2c, /* Dark grey */
    black
  );
  background-clip: text;
  -webkit-background-clip: text;

  /* Animate the background position */
  background-size: 200%; /* Makes the gradient "move" across the text */
  animation: loadingLineEffect 0.8s linear infinite; /* Linear effect, infinite loop */
}
</style>
